@import url('https://fonts.googleapis.com/css?family=Lexend+Deca&display=swap');
.App {
	height: 100%;
	width: 100%;
	background-color: black;

	font-family: 'Lexend Deca', sans-serif;
}

html {
	background-color: black;
}
